import React from "react"
import SEO from "../components/utility/SEO"
import ImageDialog from "../components/ImageDialog";
import Test1 from '../images/picture-1.jpg'
import Test2 from '../images/picture-2.jpg'
import Test3 from '../images/picture-3.jpg'
import Test4 from '../images/picture-4.jpg'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const dummyImageData = [
  {url: Test1, text: 'Test1 test test test test test test test Test1 test test test test test test test'},
  {url: Test2, text: 'Test2 test test test test test test test'},
  {url: Test3, text: 'Test3 test test test test test test test'},
  {url: Test4, text: 'Test4 test test test test test test test'},
];

const ReadySolutions = () => (
  <>
    <SEO title="Ready Solutions" />
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='h5' style={{margin: '32px 0'}}>ΕΤΟΙΜΕΣ ΛΥΣΕΙΣ ΓΙΑ ΕΣΑΣ</Typography>
      <Grid container justify={'center'}>
        {dummyImageData && dummyImageData.map((data, index) => (
          <Grid item container justify={'center'} xs={4} key={index}>
            <ImageDialog image={data.url} text={data.text}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  </>
);

export default ReadySolutions
