import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import Img from 'gatsby-image'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: theme.spacing(38)
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  dialogImage: {
    height: '80vh',
    objectFit: 'contain'
  }
}));

export default function ImageDialog({image, text, height, width, imageHeight, imageWidth, ...rest}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.root} {...rest}>
      <Button onClick={handleClickOpen} style={{width: '100%'}}>
        <Img fluid={image.fluid} alt='' className={classes.image}/>
      </Button>
      {text && <Typography variant={"body2"} style={{paddingLeft: 8}}>
        <Brightness1Icon style={{fontSize: 12, marginRight: 8}} color='primary'/>
        {text}
      </Typography>}
      <Dialog maxWidth={'xl'} open={open} onClose={handleClose}>
        {/*<Img fluid={image.fluid} alt='' className={classes.dialogImage}/>*/}
        <img src={image.url} alt='' className={classes.dialogImage}/>
      </Dialog>
    </Box>
  );
}
